body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}




@media screen and (max-width: 500px) {
  .fixedNavStyle{
    background-color: #242323;
    height: 50px;
    width: 100%;
    text-align: left;
  }
}
@media screen and (max-width: 450px) {
  .fixedNavStyle{
    background-color: #242323;
    height: 50px;
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .marginPos{
margin-left:-20px;
  }
}

.textAlignLeft{
  text-align: left!important;
}



.fixedNavStyleToggle{
  background-color: #242323;
  height: 10px;
  width: 100%;
  text-align: left;
  
}
@media screen and (max-width: 450px) {
  .fixedNavStyleToggle{
    background-color: #242323;
    height: 10px;
    width: 100%;
    text-align: left;
  }
}


ul > li{
  list-style: none !important;
  display: inline-block !important;
  padding-right : 2em;
}

.positionNavBar{
  padding: 0.5% !important;
}

.fixedNavText{
  color: white;
  font-size: 22px;
  font-style: oblique;
  object-fit: cover;
  
}
.fixedNavText:hover {

  color: #7becb4!important;
  transition: color 0.5s ease-in;
}

.sticky-navbar-shadow {
  box-shadow: 0 8px 6px -0.5px rgba(0,0,0,0.3);
}

.sticky-navbar-shadow ul li a{

}


.full-img{
  position: relative;
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
}


.fixedNavStyle{
  background-color: #242323;
  height: 80px;
  width: 100%;
  text-align: center;
  padding-bottom: 5%!important;
}





@media screen and (max-width: 1101px) {
  .fixedNavText{
      font-size: 10px;
      margin: -50%;
      
  }
  .fixedNavDash{

    margin: -50%;
  }
  .positionNavBar{
    padding: 0.5% !important;
  }



}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  background: #D7DDE5;
  font-family: "Roboto", sans-serif;
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 2px ;
  align-items: center;
  background: #18283B;
  padding-top: 10px;
}

.logo {
  color: #FFF;
  font-size: 22px;
}

ul {
  list-style: none;
}

ul li {
  display: inline-block;
}

ul li a {
  text-decoration: none;
  color: #8291A5;
  padding: 8px 30px;
  border-radius: 7px;
  transition: all .3s ease;
}

ul li a:hover,
ul li a.active {
  color: #FFF;
  background: #347c4f;
}

#icon {
  background-color: #347c4f;
  padding: 5px 7px 3px 7px;
  border-radius: 4px;
  cursor: pointer;
  transition: all .3s ease;
}

#icon svg {
  color: #FFF;
  height: 28px;
  transition: all .3s ease;
}

#icon:hover {
  background: #FFF;
}

#icon:hover svg {
  color: #347c4f;
}

#checkbox,
#icon {
  display: none;
}

.link {
  position: fixed;
  background-color: #D12322;
  padding: 23px 40px;
  right: -100px;
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);
  transform: rotate(-90deg);
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  text-decoration: none;
  text-transform: capitalize;
  transition: all .1s ease-in-out;
}

.link i {
  padding-left: 7px;
}

.link:hover {
  text-decoration: underline;
  background-color: black;
}
@media screen and (max-width: 768px) {
  nav {
      border-bottom: 1px solid #455468;
  }

  ul {
      position: absolute;
      width: 100%;
      height: calc(100vh - 71px);
      background-color: #18283B;
      left: 0;
      top: 0;
      text-align: center;
      display: none;
      margin-top: 71px;
  }

  ul li {
      display: block;
      padding: 10px 15px;
  }

  ul li a {
      display: block;
      padding: 10px 0;
  }

  #icon {
      display: block;
  }

  #checkbox:checked~ul {
      display: block;
  }
}





.HomeBanner{
  box-shadow: 0 8px 6px -0.5px rgba(0,0,0,0.3);
}
.Heading-One {
  font-size: xx-large;
  margin-top: 2%;
}

.divider {
  border:0;
  height:1px;
  background-image: linear-gradient(to right, #f0f0f0, #455468, #18283B, #f0f0f0);
  width:50%;
  margin-left: 25%;
}


.inputGroup {
  background-color: #fff;
  margin-bottom: 0px;
}
.radio-item {
  display: inline-block;
  width: 30%;
  margin: 10px 0;
}
.inputGroup label {
  padding: 12px 30px;
  width: 100%;
  padding-right: 8%;
  display: block;
  text-align: left;
  cursor: pointer;
  position: relative;
  /* z-index: 2; */
  transition: color 0.5s ease-in;
  overflow: hidden;
  color: #343a40;
  font-weight: 400;
}
@media only screen and (max-width: 380px) {
  .inputGroup label {
    padding: 12px 30px;
    width: 100%;
    padding-right: 20%;
    display: block;
    text-align: left;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 0.5s ease-in;
    overflow: hidden;
    color: #343a40;
    font-weight: 400;
  }
}
.inputGroup label:before {
  width: 20px;
  height: 10px;
  border-radius: 50%;
  content: '';
  margin-bottom: 0px;
  background-color: #18283B;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
          transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.inputGroupBilling label:before{
  background-color: #61CC3B !important;
}

.confirmError{
  color:red!important;
  font-weight: bold;
}




.inputGroup label:after {
  width: 32px;
  height: 32px;
  content: '';
  border: 2px solid #D1D7DC;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  /* z-index: 2; */
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.5s ease-in;
}
@media only screen and (max-width: 500px) {
  .inputGroup label:after {
    width: 32px;
    height: 32px;
    content: '';
    border: 2px solid #D1D7DC;
    /* background-color: #fff; */
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    /* z-index: 2; */
    position: absolute;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.5s ease-in;
  }
}




.inputGroup input:checked ~ label {
  color: #a0a0a0;
}
.inputGroup input:checked ~ label:before {
  -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
          transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-color: #347c4f;
  border-color: #347c4f;
}
.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  /* z-index: 2; */
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}
code {
  background-color: #9AA3AC;
  padding: 0 8px;
}
.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #18283B;
}
.resize-input-95{
  width: 95% !important;
}

.btnFormat{
  cursor: pointer;
outline: 0;
color: #fff;
background-color: #18283B;
border-color: #18283B;
display: inline-block;
font-weight: 400;
line-height: 1.5;
text-align: center;
border: 1px solid transparent;
padding: 6px 35px;
font-size: 16px;
border-radius: .25rem;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;


}

.btnFormat:hover {
  color: #18283B;
  background-color: #347c4f;
  border-color: #347c4f;
}


.photoupldtext{
  font-size: 22px;
  font-weight: bold;
}
.photoupldFile{
  font-size: 18px;
  font-weight: bold;
  margin-left: 10%;
}
.tableHeadCss{
  background-color: #18283B;
  color: white;
}
.tableborderCss{
  box-shadow: 0 8px 6px -0.5px rgba(0,0,0,0.3);

}
.profilePicImageSz
{
  box-shadow: 0 8px 6px -0.5px rgba(0,0,0,0.3);
  width: 80px!important;
}


.titleSelectAT{

}
.titleSelect{
  border-radius: 5%;
  border-color: lightgray!important;
  /* color:gray!important; */
}
.selectBx{
  height: 50px!important;
  width: 100%!important;
  padding-left: 1%!important;
}
.editHover{
  color: #18283B;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0 8px 6px -0.5px rgba(0,0,0,0.3);
}
.editHover:hover {
  color: #347c4f;
  box-shadow: 0 8px 6px -0.5px rgba(0,0,0,0.3);
}

.modelSize{
  width: 100%!important;
  width: 1000px!important;
}
.meterreadModalBackdrop{
  width: 1000px!important;
}
input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}

.test{
  color: white !important;
  cursor: pointer;
}
.ContainerOverflow{
  width: 40%;
  overflow-x: scroll;
}
.exPointer
{
  margin-left: 2.5%;
}
.fieldCss{
    color: white !important;
    background-color: #18283B !important;
}

.logoutbtn{
  color: #9AA3AC;
  cursor: pointer;
}
.addNewUserCss{
  margin-left: 1%;
}
.orderTypeSelect{
  height: 38px!important;
}



.redError{
  color:red;
}


.container {
  padding: 2px 16px;
}
/* Card Formatting Home Page */
.ulWidthHomePage
{
  width: 100%;
  margin-left:-10px!important;
}
.liWidth
{
  width: 100%;
}

@media screen and (max-width: 500px) {
  /* Card formatting Home Page */
  .ulWidthHomePage
  {    width: 120%!important;    margin-left:-8%!important;  }
  .liWidthHomePage
  {    width: 100%!important;   }
}
/* home page tile width */
@media only screen and (min-width: 1500px) {
  .liWidthHomePage{
    width: 1500px!important
  }
}


img {
  display: block;
  width: 100%;
  height: auto;
}

.description {
  padding: 10px;
}

button {
  display: block;
  margin-top: 10px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.ulWidthHomePage{
  display: inline-block!important;
  /* or use "display: inline;" instead */
  /* optional - adds spacing between items */
}
.profilePicImageSzViewuser{
  width: 15%;
  box-shadow: 0 8px 6px -0.5px rgba(0,0,0,0.3);
  text-align: center !important;
  margin-left:40%!important;
}





























.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.card {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.tableMyAcc{
  width: 100%;
  text-align: left;
}
.tableMyAccBody{
  padding-left: 10PX;
}
.tableMyAccTr{
  padding-left: 10PX;
}
.tableMyAccTrd{
  padding-left: 10%;
}
tr:nth-child(odd) {background: #e6e6e6}

.profileImageCssHome{
  width: 30%;
  margin-left: 35%;
}
.cardProfileImageHomeCss{

}
.liWidthProfileUserCss{
  width: 30%;
}

.rfaLogoSize{
  width: 4%;
  padding: 2px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
@media screen and (max-width: 500px) {
  .rfaLogoSize{
    width: 15%;
    padding: 2px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
}

.homepagetileimagecss{
  width:300px;
  height: 200px;
}

.memberstatusColor{
  background-color: rgb(247, 142, 45)!important;
}